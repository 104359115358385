@import 'src/styles/scss/variables';

.inbox-container {
  position: relative;
  width: 40px;
  cursor: pointer;

  &__badge {
    height: 16px;
    min-width: 16px;
    width: 16px;
    background-color: $palette-alert600;
    border-radius: $radius-round;
    position: absolute;
    top: -6px;
    right: 3px;
  }
}