@import 'src/styles/scss/variables';

.icon-container {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: $radius-xxl;
  width: 96px;
  height: 96px;

  &--info {
    background-color: $palette-primary50;

    svg {
      path {
        fill: $palette-primary600;
      }
    }
  }

  &--alert {
    background-color: $palette-alert50;

    svg {
      path {
        fill: $palette-alert600;
      }
    }
  }

  &--success {
    background-color: $palette-success50;

    svg {
      path {
        fill: $palette-success600;
      }
    }
  }
}

.text-container {
  text-align: center;
  margin-top: $spacing-xxl;
}