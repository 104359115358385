@import 'src/styles/scss/variables';

.side-bar-container {
  grid-column: sidebar-start / sidebar-end;
  grid-row: header-start / content-end;

  &__static {
    height: 100%;
    width: inherit;
    display: grid;
    grid-template-rows: calc(40px + 24px) 1fr max-content;
    position: fixed;
    transition: all 0.5s;
    z-index: $layer-sidebar;
    background-color: $palette-grey900;
  }

  &__logo-anchor {
    align-self: end;
    cursor: pointer;
    margin-left: $spacing-m;
  }

  &__menu {
    margin-top: 40px;
    width: 100%;
  }

  &__user {
    position: relative;
    padding: $spacing-m;
    cursor: pointer;

    &-avatar {
      position: relative;
    }
  }
}

.navigation-item {
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: $spacing-l;

  &:after {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $palette-body;
    border-radius: 0 $radius-xxl $radius-xxl 0;
    width: $spacing-xxs;
    height: 100%;
  }

  &--selected {
    &:after {
      display: block;
    }
  }

  & span {
    margin-left: $spacing-m;
  }


  &__opacity {
    opacity: 0.5;
    
    &--selected {
      opacity: 1;
    }
    
    &:hover {
      opacity: 1;
    }
  }
}

.user-menu {
  position: absolute;
  width: 408px;
  left: 0;
  bottom: $spacing-m;
  border-radius: $radius-s;

  &__menu {
    margin-left: 88px;
    min-width: 320px;
    background-color: $palette-grey900;
    box-shadow: 0px 0px 8px rgba(31, 41, 55, 0.1);
    border-radius: $radius-s;
    overflow: hidden;
  }

  &__complete-profile {
    padding: $spacing-xs $spacing-m;
  }

  &__item {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: $spacing-m;
    padding: $spacing-xs $spacing-m;
    cursor: pointer;
  
    &:hover {
      background-color: $palette-grey700;
    }

    &--disabled {
      cursor: default;
      opacity: 0.5;

      &:hover {
        background-color: $palette-grey900;
      }  
    }

    &--soft-disabled {
      cursor: default;
      &:hover {
        background-color: $palette-grey900;
      }  
    }
  }

  &__block {
    padding: $spacing-xs 0;

  }

  &__footer {
    padding: $spacing-xs 0;
    border-top: 1px solid $palette-grey700;
  }

  .grey800 {
    background-color: $palette-grey800;
    border-bottom: 1px solid $palette-grey700;
  }
}


.fill-style {
  path {
    fill: $palette-body;
  }
}

.stroke-style {
  path {
    stroke: $palette-body;
  }
}

.venture-selected {
  path {
    fill: $palette-body;
    stroke: $palette-body;
  }
  g {
    path:nth-child(7) {
      fill: $palette-body;
    }
    path:nth-child(9) {
      fill: $palette-grey900;
    }
    path:nth-child(10) {
      stroke: $palette-grey900;
    }
  }
}

.equity-offering-selected {
  path {
    fill: $palette-body;
    stroke: $palette-body;
  }
  path:last-child {
    stroke: $palette-grey900;
  }
}

.search-selected {
  path {
    fill: $palette-body;
    stroke: $palette-body;
  }
  path:nth-child(5) {
    fill: $palette-grey900;
  }
}

.path-stroke-style-change {
  path {
    stroke: $palette-body;
    fill: $palette-body;
  }
}

.wait-list-selected {
  path {
    stroke: $palette-body;
  }
  path:nth-child(2) {
    stroke: $palette-grey900;
  }
}
