@import 'src/styles/scss/variables';
.inside-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-loader;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s;
}

.spinner {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.in-modal {
    margin: auto;
    display: block;
    shape-rendering: auto;
  }
}
