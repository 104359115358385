@import 'src/styles/scss/variables';

.card {
  padding-top: calc($spacing-m - 1px);
  padding-bottom: calc($spacing-m - 1px);
  padding-left: $spacing-m;
  padding-right: $spacing-m;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: $radius-s;
  background: $palette-body;
  border: 1px solid $palette-grey300;
  cursor: pointer;

  &--active {
    border: 1px solid $palette-primary600;
    box-shadow: 0 0 0 1px $palette-primary600;
  }

  &--disabled {
    background: $palette-grey50;
  }
}

.indicator {
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: $radius-round;
  top: $spacing-m;
  right: $spacing-m;
  border: 1px solid $palette-grey300;
  
  &--active {
    border: 8px solid $palette-primary600;
  }
}

.search-icon {
  svg {
    path {
      stroke: $palette-primary600;
    }
  }

  &:hover {
    svg {
      path {
        stroke: $palette-primary700;
      }
    }
  }
}