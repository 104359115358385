@import '../../styles/scss/variables'; 

.commitment {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;

  span {
    display: inline-block;
    margin-left: $spacing-m;
  }

  &__empty {
    span {
      margin-left: $spacing-xs;
    }
  }

  &__image-container {
    display: flex;
    position: relative;
    flex-direction: row-reverse;
  }

  &__styled-image-container-24 {
    &:not(:first-child) {
      margin-right: -$spacing-xxs;
    }
  }

  &__styled-image-container-48 {
    &:not(:first-child) {
      margin-right: -$spacing-xs;
    }
  }

  &__hided-list {
    span {
      margin-left: 0;
    }
  }
}


.progress-wrapper {
  position: relative;
  align-self: center;
}

.progress-container {
  width: 100%;
  border-radius: $radius-xxl;
  overflow: hidden;
  margin-top: calc($spacing-m - 1px);
  margin-bottom: calc($spacing-s - 2px);
  & > span {
    text-align: right;
  }

  &.without-margin {
    margin: 0px;
  }

  &.with-border {
    border: 1px solid $palette-grey200;
  }
}

.filler {
  height: 100%;
  transition: width 1s ease-in-out;
  border-radius: inherit;
  text-align: right;

  &.left-border-radius {
    border-radius: $radius-xxl 0 0 $radius-xxl;
  }

  &.border-radius {
    border-radius: inherit;
  }
}

.point-wrapper {
  position: absolute;
  width: 4px;
  height: 16px;

  &--top {
    border-radius: $radius-xxl $radius-xxl 0 0;
    bottom: 0;
  }

  &--bottom {
    border-radius: 0 0 $radius-xxl $radius-xxl;
    top: 0;
  }

  &--mobile {
    height: 8px;
  }
}

.point-stats {
  position: absolute;
  right: 0;

  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: $spacing-xs;

  &.direction-left {
    right: calc(100% - 2px);
    left: 0;
  }
}

.point-dot-wrapper {
  position: absolute;
  border-radius: 100%;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.simple-progress-container {
  height: 8px;
  border-radius: $radius-xxl;
  overflow: hidden;
}