@import 'src/styles/scss/variables'; 

.btn {
  font-weight: 600;
  cursor: pointer;
  border: none;
  border-radius: $radius-s;
  color: $palette-grey1000;

  &__medium {
    font-size: 14px;
    line-height: 20px;
    padding: calc($spacing-s - 1px) calc($spacing-m - 4px);
  }

  &__large {
    font-size: 16px;
    line-height: 24px;
    padding: calc($spacing-s - 1px) $spacing-l;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &--text {
    background-color: transparent;
    color: $palette-primary600;
    padding: 0;

    &:hover:not([disabled]) {
      color: $palette-primary700;
    }
  }

  &--secondary {
    background-color: $palette-body;
    border: 1px solid $palette-grey300;

    &:hover:not([disabled]) {
      background-color: $palette-grey50;
    }
  }

  &--disapprove {
    background-color: $palette-alert600;
    border: 1px solid $palette-alert600;
    color: $palette-body;

    &:hover:not([disabled]) {
      background-color: $palette-alert700;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &--approve {
    background-color: $palette-success600;
    border: 1px solid $palette-success600;
    color: $palette-body;

    &:hover:not([disabled]) {
      background-color: $palette-success700;
    }
  }

  &--inform {
    background-color: $palette-primary600;
    border: 1px solid $palette-primary600;
    color: $palette-body;

    &:hover:not([disabled]) {
      background-color: $palette-primary700;
    }
  }

  &--dashed {
    background-color: inherit;
    border: 1px dashed $palette-grey200;
    color: $palette-primary600;

    &:hover:not([disabled]) {
      color: $palette-primary700;
    }
  }

  &--round-primary {
    background: $palette-primary600;
    color: $palette-body;
    border: 1px solid $palette-primary600;
    border-radius: $radius-xxl;
    text-transform: capitalize;

    &:hover:not([disabled]) {
      background-color: $palette-primary700;
    }
  }

  &--round-white {
    background: $palette-body;
    color: $palette-grey800;
    border: 1px solid $palette-grey300;
    border-radius: $radius-xxl;
    text-transform: capitalize;

    &:hover:not([disabled]) {
      background-color: $palette-grey50;
    }
  }

  &--delete {
    background-color: $palette-body;
    border: 1px solid $palette-grey300;
    color: $palette-alert600;

    &:hover:not([disabled]) {
      background-color: $palette-grey50;
    }
  }

  &--black {
    background-color: $palette-ternary;
    border: 1px solid $palette-bodyOpacity50;
  }
}
