@import 'src/styles/scss/variables'; 

.modal-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: $layer-modal;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $palette-body;
  border-radius: $radius-m;
  min-height: min-content;
  max-height: calc(100vh - 48px); // 24px*2

  &--small {
    width: 560px;
  }
  
  &--large {
    width: 960px;
  }

  &--visible {
    overflow: visible;
  }
}

.modal-body {
  padding: $spacing-l;
  overflow-y: auto;
  position: relative;
}

.body-loader-container {
  height: 80px;
  
  svg {
    left: calc(50%);
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-left: $spacing-l;
  padding-right: $spacing-l;
  position: relative;
  background-color: $palette-body;

  &__medium {
    padding-bottom: $spacing-s;
    padding-top: calc($spacing-s - 1px);

    &[data-border="none"] {
      padding-top: $spacing-s;
    }
  }

  &__large {
    padding-bottom: $spacing-l;
    padding-top: calc($spacing-l - 1px);

    &[data-border="none"] {
      padding-top: $spacing-l;
    }
  }

  &--border {
    border-top: 1px solid $palette-grey200;
  }
}

.modal-header {
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: $spacing-l;
  padding-left: $spacing-l;
  padding-right: $spacing-l;

  &__medium {
    padding-top: calc($spacing-l - 2px);
    padding-bottom: calc($spacing-l - 3px);
    
    &[data-border="none"] {
      padding-bottom: calc($spacing-l - 2px);
    }
  }

  &__large {
    padding-top: calc($spacing-l - 4px);
    padding-bottom: calc($spacing-l - 5px);

    &[data-border="none"] {
      padding-bottom: calc($spacing-l - 4px);
    }
  }

  &--border {
    border-bottom: 1px solid $palette-grey200;
  }

  &--back-btn {
    grid-template-columns: max-content 1fr max-content;
  }

  // svg {
  //   cursor: pointer;
  //   margin: $spacing-xs 0;
  // }
}

.close-icon {
  path {
    fill: $palette-grey500;
    stroke: $palette-grey500;
  }
}