@import 'src/styles/scss/variables';

.step-wrapper {
  margin: auto;

  max-width: 480px;
}

.content-wrapper {
  height: calc(100vh - 72px);
  @media (max-width: 480px) {
    padding: $spacing-xl $spacing-m;
  }

  &.full-screen {
    height: 100vh;
  }
}
